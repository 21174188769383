import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import $ from "jquery";
import './Navigation.scss';
import utils from '../utilities';
import _ from 'underscore';

class Navigation extends Component {

    state = {
        menuVisible: false,
        navOpen: false,
        isClosing: false,
        isCurrentlyOpen: false,
        menuInit: false
    }

    initMenu = () => {

        if (!this.state.menuInit) {
            // Logic for revealing text within menu
            var hide = function() {
                $(".menu-item span.hidden").css("width", "0"); //hide the .hidden spans
                
                if (utils.getDocWidth() > 900) {
                    $(".menu-item").mouseenter(function() { // on mouseenter, set width to the custom data property width
                        if (utils.getDocWidth() > 900) {
                            $(this)
                                .find("span.hidden")
                                .each(function() {
                                var w = $(this).attr("data-init-width");
                                $(this).css("width", w + "px");
                            });
                        }
                    });
                    $(".menu-item").mouseleave(function() { // on mouseout, set width to 0
                        if (utils.getDocWidth() > 900) {
                            $(this)
                                .find("span.hidden")
                                .each(function() {
                                $(this).css("width", 0 + "px");
                            });
                        }
                    });
                }
            };

            var initMenu = function(hide) { //use a callback to trigger the hide() function after setting a custom data property to hold the widths on each span
                
                $("span").each(function() {
                    $(this).attr("data-init-width", $(this).width());
                });

                hide();
            };

            // grab the initial 'span.hidden' widths first, then hide them
            //css transitions don't work between numeric values and 'initial'
            initMenu(hide); 

            this.setState({menuInit: true});
        }
    }

    toggleNav = () => {

        var ctxt = this;

        if (this.state.navOpen === false) {
            ctxt.setState({navOpen : !ctxt.state.navOpen});   
            ctxt.setState({isCurrentlyOpen : !ctxt.state.isCurrentlyOpen});   
        } else {   
            ctxt.setState({isCurrentlyOpen : !ctxt.state.isCurrentlyOpen});   
            const startClose = () => {
                return new Promise(function (resolve, reject) {
                ctxt.setState({isClosing: true});
                resolve();
                });
            }

            const togNav = () => {
                return new Promise(function (resolve, reject) {
                    setTimeout((function () {
                        ctxt.setState({navOpen : !ctxt.state.navOpen}); 
                        resolve();
                    }), 600)
                });
            }

            const resetClose = () => {
                return new Promise(function (resolve, reject) {
                    ctxt.setState({isClosing: false});
                    resolve();
                });
            }

            startClose().then(togNav).then(resetClose);
        }
    }

    componentDidMount() {
        const nav = document.querySelector(".navigation-list");
        nav.classList.add("nav-list-exit-done");


        const nav_container = document.querySelector('.navigation');
        let lastScrollTop = 0;
        let delta = 25;
        const navbarHeight = nav_container.clientHeight;

        document.addEventListener('scroll', _.throttle(() => {
            if (utils.getDocWidth() < 1250) {
                var st = window.pageYOffset;
                
                // // Make sure they scroll more than delta
                if(Math.abs(lastScrollTop - st) <= delta)
                    return;
                
                // If they scrolled down and are past the navbar, add class .nav-up.
                // This is necessary so you never see what is "behind" the navbar.
                if (st > lastScrollTop && st > navbarHeight/4){
                    // Scroll Down
                    nav_container.classList.remove('nav-down');
                    nav_container.classList.add('nav-up');
                } else {
                    // Scroll Up
                    if(st + $(window).height() < $(document).height()) {
                        nav_container.classList.add('nav-down');
                        nav_container.classList.remove('nav-up');
                    }
                }
                lastScrollTop = st;
            }
        }, 100))

        window.addEventListener('resize', _.debounce( () => {
            if (utils.getDocWidth() > 1249) {
                nav_container.classList.add('nav-down');
                nav_container.classList.remove('nav-up');
            }
          }, 500))

    }
    
    render() {
        return (
            <div>
            <div className={this.state.navOpen ? "navBack navigation" : "navigation"} onClick={ () => { this.toggleNav() } }  >
                <div className={this.state.navOpen ? "navBack ham-menu" : "ham-menu"}></div>
            </div>
                <CSSTransition
                    in={this.state.navOpen}
                    timeout={1000}
                    classNames="nav-list"
                    onEnter={() => this.initMenu()}
                >
                <ul className={ this.state.navOpen && this.state.isClosing ? "navigation-list open closing" : this.state.navOpen ? "navigation-list open" : "navigation-list" }>
                    <li className="menu-item work">
                        <Link to='/' onClick={ () => { this.toggleNav() } }>
                            <div><span className="hidden">Show me the&nbsp;</span><span>work</span><span className="hidden">.</span></div>
                        </Link>
                        
                    </li>
                    <li className="menu-item">
                        <Link to='/experiments' onClick={ () => { this.toggleNav() } }>
                            <div><span className="hidden">I make&nbsp;</span><span>experiments</span><span className="hidden">&nbsp;in code.</span></div>
                        </Link>
                    </li>
                    {/* <li className="menu-item">
                        <div><span className="hidden">A few of my&nbsp;</span><span>thoughts</span><span className="hidden">.</span></div>
                    </li> */}
                    <li className="menu-item">
                        <Link to='/about' onClick={ () => { this.toggleNav() } }>
                            <div><span className="hidden">A little more&nbsp;</span><span>about</span><span className="hidden">&nbsp;me.</span></div>
                        </Link>
                    </li>
                </ul>
                </CSSTransition>
            </div>
        )
    }
}

export default Navigation
