import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navigation from './components/Navigation';
import Experiments from './components/Experiments';
import About from './components/About';
import Hero from './components/Hero';
import Post from './components/Post';
import Password from './components/Password';
import './App.scss';

class App extends Component {

  state = {
    posts: [],
    isAuthenticated: false,
    needsAuthetication: false,
    currentProtectedRoute: ''
  }

  componentDidMount() {
    // fetch posts by the "homepage" category
    fetch("http://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts?categories=5")
    .then(res => res.json())
    .then(homepage_posts => {
      // for each post get the embeddable version with images
      for (const post in homepage_posts) {
        fetch(`http://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts/${homepage_posts[post].id}/?_embed`)
        .then(res => res.json())
        .then(post => {
          // make a copy of the posts array in state
          let posts_array = this.state.posts;
          // add the new post to the list
          posts_array.push(post);
          // sort the list of posts by the post's date property to get a predictable order
          posts_array.sort((post1, post2) => {
            const d1r = new Date(post1.date);
            const d1 = d1r.getTime();
            const d2r = new Date(post2.date);
            const d2 = d2r.getTime();
            return (d2 > d1) ? 1 : -1;
          })
          // set the state to update the posts array
          this.setState({posts: posts_array})
        });
      }
    })
    .catch(err => console.log(err))
  }

  needsAuthentication = (url) => { 
    this.setState({ needsAuthentication: true })
    this.setState({ currentProtectedRoute: url})
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Navigation />
          <Route exact path="/" render={() => (
            <React.Fragment>
              {/* INTRO SECTION */}
              <Hero {...this.state} />
              {/* PROJECT SECTION */}             
              <div id="projects" className="project-container home-project-container">
                { this.state.posts && this.state.posts
                .filter( post => {
                  return post.categories.includes(5);
                })
                .map( post => {
                  return (
                    (<article className="project" key={post.id}>
                      <h2 className="home-project-title" title={post.title.rendered}>
                        <Link to={{
                          pathname: `/projects/${post.slug}`,
                        }}>{post.title.rendered}</Link>
                      </h2>
                      <div className="project-image-container">
                        <Link to={{
                          pathname: `/projects/${post.slug}`,
                        }}>
                          <img alt="" src={ post.acf.home_img ? post.acf.home_img : post._embedded['wp:featuredmedia']['0'].source_url} /> 
                        </Link>
                      </div>
                      <Link className="project-to-link" to={{
                        pathname: `/projects/${post.slug}`,
                      }}>View Project →</Link>
                    </article>)
                  )
                })}
              </div>
          </React.Fragment>
          )} />
          <Route exact path="/about" render={(routeProps) => {
            return (<About {...this.state} {...routeProps} />)
          }} />
          <Route exact path="/experiments" render={(routeProps) => {
            return (<Experiments {...this.state} {...routeProps} />)
          }} />
          <Route path="/projects/:slug" render={(routeProps) => {
            return (<Post {...this.state} {...routeProps} />)
          }}/>
          <Route path="/experiments/:slug" render={(routeProps) => {
            return (<Post {...this.state} {...routeProps} />)
          }}/>
          <Route path="/auth" component={Password} />
        </Router>
      </div>
    )
  }
}

export default App
