import React, { Component } from 'react';
import './About.scss';

class About extends Component {
  render() {
    return (
      <div>
        <div className="project-container about-content">    
          <div className="about-pic">
            <img alt="" src="/images/me.jpg" />
          </div>

          <h2 className="emphasis">I'm a designer and front end coder working in Cambridge, MA.</h2>

          <p>In 2016, I received a BA in Fine Arts and in Visual Studies from the University of Pennsylvania where I concentrated in graphic design and studied the relationship between art, design, and science.</p>

          <p>Since then, I’ve worked at IBM Watson Health as a UX Designer humanizing complex patient safety systems in 2017, designing for patients with chronic hypertension in 2018, and, most recently, enabling designers and developers to work more efficiently by providing accessible design and code assets through Watson Health’s design system (called PAL) in 2019.</p>

          <p>My journey began in the graphic design space. Then, I fell in love with making my own designs come to life on the web, and have gradually become something of a tool-agnostic, hybrid designer and (presentational) coder. I’m addicted to learning new things — especially new techniques and technologies that help me realize my vision or more accurately communicate my design intent.</p>

        </div>
      </div>
    )
  }
}

export default About
