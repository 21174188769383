import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Experiments.scss';

class Experiments extends Component {

    state = {
        posts: []
    }

    componentDidMount() {
        fetch("http://nport.vincentsnaggdesign.com/wp-json/wp/v2/posts?_embed")
        .then(res => res.json())
        .then(posts => {
          this.setState({posts})
        })
        .catch(err => console.log(err))
    }

  render() {
    return (
      <div>

        <div className="page-title-container">
          <h2 className="page-title project-title">Experiments</h2>
        </div>

        {/* PROJECT SECTION */}
        <div id="projects" className="project-container home-project-container">
            { this.state.posts && this.state.posts
            .filter( post => {
                return post.categories.includes(6);
            })
            .map( post => {
                return (
                (<article className="project" key={post.id}>
                    <h2 className="home-project-title" title={post.title.rendered}>
                    <Link to={{
                        pathname: `/experiments/${post.slug}`,
                    }}>{post.title.rendered}</Link>
                    </h2>
                    <div className="project-image-container">
                    <Link to={{
                        pathname: `/experiments/${post.slug}`,
                    }}>
                        <img alt="" src={ post.acf.home_img ? post.acf.home_img : post._embedded['wp:featuredmedia']['0'].source_url} /> 
                    </Link>
                    </div>
                    <Link className="project-to-link" to={{
                    pathname: `/experiments/${post.slug}`,
                    }}>View Project →</Link>
                </article>)
                )
            })}
        </div>
      </div>
    )
  }
}

export default Experiments
